<template>
 <div>
   <div class="search-list-view">
     <van-list
       v-model="loading"
       :finished="finished"
       finished-text="没有更多了"
       @load="onLoad"
       :offset="offset"
     >
       <div v-for="item in list" :key="item" :title="item" class="search-list">
         <div>
           <svg t="1635327179504" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7308" width="200" height="200"><path d="M835.5 743.9c-1-2.5-2.1-4.9-3.2-7.4-13.3-29.5-50.6-38.9-76.3-19.2l-0.6 0.6c-17 17-21.7 42.6-12.3 64.7 13.1 30.7 20.3 64.4 20.3 99.8 0 1.5-1.2 2.7-2.7 2.7H254.6c-1.5 0-2.7-1.2-2.7-2.7 0-138.8 111.3-250.8 249.3-254.4h4c13.1 0 26-1 38.7-3 4.9-0.7 9.9-1.6 15-2.8 0.3-0.1 0.6-0.1 1-0.2 40.4-9 77.9-28 109.4-55.8 0.2-0.2 0.5-0.4 0.7-0.7 3.8-3.4 7.5-6.9 11.2-10.5 47-47 72.9-109.6 72.9-176.1s-25.9-129-72.9-176.1c-47-47-109.6-72.9-176.1-72.9s-129 25.9-176.1 72.9c-47 47-72.9 109.6-72.9 176.1S282 507.9 329 555c4.2 4.2 8.6 8.3 13.1 12.2-31.7 16.6-60.5 37.9-86.1 63.4-32.7 32.7-58.3 70.7-76.2 113.1-18.6 43.9-28 90.5-28 138.5 0 20.5 1.8 41.1 5.3 61.3 4.2 24 25 41.4 49.3 41.4h602.5c24.3 0 45.1-17.5 49.3-41.4 3.5-20.2 5.3-40.8 5.3-61.3 0-47.8-9.4-94.4-28-138.3z m-326-513.7c78.8 2.3 142.7 66.4 144.6 145.2C656 453 598.2 517.7 523.5 527c-5.3-0.2-10.5-0.4-15.8-0.4-6.5 0-13 0.2-19.4 0.5-74.2-8.4-132.1-71.6-132.1-148-0.1-83.6 69.1-151.4 153.3-148.9z" fill="#80DEEA" opacity=".8" p-id="7309"></path><path d="M212.9 929.3m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3" opacity=".8" p-id="7310"></path><path d="M416.9 573.9m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3" opacity=".8" p-id="7311"></path><path d="M802.5 929.8m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z" fill="#54D2E3" opacity=".8" p-id="7312"></path></svg>
         </div>
         <div>
           <p>{{item.mobile.replace(/(\d{3})\d*(\d{4})/,'$1****$2')}}</p>
           <span>{{item.username}}</span>
         </div>
         <div>
           <p v-if="item.level==1">会员用户</p>
           <p v-if="item.level==0">普通用户</p>
           <span>{{item.createTime}}</span>
         </div>
       </div>
     </van-list>
   </div>
 </div>
</template>

<script>
export default {
   data() {
    return {
      list: [],
      loading: false,
      finished: false,
      phoneNumberCode:"",
      page:1,
      limit:15,
      offset:"-10"
    };
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:#f5f5f5')
  },
  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  },
  methods:{
    onLoad() {
          if(this.loading){
            return;
          }  
          this.loading = true;

            console.log("第几次加载",this.page);
            this.axios.post('/app/user/team', {
              page:this.page,
              limit:this.limit
            }).then((response) => {
              this.list = [...this.list,...response.list];
              console.log(response);
              this.loading = false;
              if(response.list.length<response.pageSize){
                this.finished = true;
              }
              this.page = this.page+1;
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
      }
  }
};
</script>

<style scoped>
.img-icon {
  height: 20px;
}
.search-list-view{
  min-height: 85vh;
  background: #ffffff;
  margin: 10px;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}
.search-list-view .search-list{
  display: flex;
  margin: 20px 0;
}
.search-list-view .search-list div{
  vertical-align: middle;
}
.search-list-view .search-list div p{
  vertical-align: middle;
  margin: 3px 0;
}
.search-list-view .search-list div:nth-child(2){
  margin-left: 15px;
}
.search-list-view .search-list div:nth-child(2) span,.search-list-view .search-list div:nth-child(3) span{
  font-size: 13px;
  color: #cecece;
}
.search-list-view .search-list div:nth-child(3){
  position: absolute;
  right: 20px;
}
.search-list-view .search-list div:nth-child(3) p{
  color: #2BD67E;
  text-align: right;
}
</style>
